import { FC } from 'react'
import Section from '@BidftaStrapi/Components/Section'
import { SectionComponent } from '@BidftaStrapi/Components/types'
import classNames from 'classnames'

const Homepage: FC<{
  content: SectionComponent[][]
}> = ({ content = [] }) => {
  return (
    <div className={''}>
      {content.map((sectionGroup, i) => {
        const numOfColumns = sectionGroup.reduce(
          (acc, curr) => curr.span + acc,
          0
        )

        return (
          <div
            key={i}
            className={classNames(
              `mx-auto main-container mobile-padding mt-0 grid grid-cols-${
                numOfColumns >= 4 && numOfColumns % 2 == 0 ? 2 : 1
              } gap-x-2`,
              `lg:grid-cols-${numOfColumns}`
            )}
          >
            {sectionGroup.map((sectionData, j) => (
              <Section {...sectionData} key={`section-${i}-${j}`} />
            ))}
          </div>
        )
      })}
    </div>
  )
}
export default Homepage
