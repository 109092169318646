import { FC, ReactNode } from 'react'
import { CardComponent } from '@BidftaStrapi/Components/types'
import { CardElement } from '@BidftaStrapi/Elements/types'
import Link from 'next/link'
import classNames from 'classnames'

const Card: FC<CardComponent & { span: number }> = ({
  type,
  color,
  gradient,
  image,
  position,
  edges,
  mobileType,
  mobileColor,
  mobileGradient,
  mobileImage,
  mobileEdges,
  span,
  href,
  hasNoExtraPadding,
  minHeight = 20,
  mobileMinHeight = 12,
  tabletMinHeight,
  children,
}) => {
  const background = {
    type,
    color,
    gradient,
    image,
    edges,
    position,
  }

  const mobileBackground = {
    type: mobileType || type,
    color: mobileColor,
    gradient: mobileGradient,
    image: mobileImage,
    edges: mobileEdges,
    position,
  }

  if (mobileType)
    return (
      <Wrapper href={href} span={1} hasNoExtraPadding={hasNoExtraPadding}>
        <section
          className={`hidden md:block ${
            background?.edges === 'flat' ? '' : 'md:rounded-xl'
          } col-span-${span}`}
          style={getBackground(background)}
        >
          {children}
        </section>
        <section
          className={`md:hidden p-4 ${
            background?.edges === 'flat' ? '' : 'rounded-xl'
          }`}
          style={getBackground(mobileBackground)}
        >
          {children}
        </section>
      </Wrapper>
    )

  return (
    <Wrapper href={href} span={span} hasNoExtraPadding={hasNoExtraPadding}>
      <section
        className={classNames(
          `hidden md:block xl:block h-full ${
            background?.edges === 'flat' ? '' : 'rounded-xl'
          }`,
          tabletMinHeight ? 'lg:hidden' : ''
        )}
        style={{
          ...getBackground(background),
          minHeight: (type === 'image' ? minHeight : 0) + 'rem',
        }}
      >
        {children}
      </section>
      <section
        className={classNames(
          `h-full ${background?.edges === 'flat' ? '' : 'rounded-xl'}`,
          tabletMinHeight ? 'hidden lg:block xl:hidden' : 'hidden'
        )}
        style={{
          ...getBackground(background),
          minHeight: (type === 'image' ? tabletMinHeight : 0) + 'rem',
        }}
      >
        {children}
      </section>
      <section
        className={classNames(
          `md:hidden h-full ${background?.edges === 'flat' ? '' : 'rounded-xl'}`
        )}
        style={{
          ...getBackground(background),
          minHeight: (type === 'image' ? mobileMinHeight : 0) + 'rem',
        }}
      >
        {children}
      </section>
    </Wrapper>
  )
}

const Wrapper: FC<{
  children: ReactNode
  href?: string
  span: number
  hasNoExtraPadding?: boolean
}> = ({ children, span = 1, href, hasNoExtraPadding }) =>
  href ? (
    <Link
      href={href}
      className={`m${
        hasNoExtraPadding ? 't' : 'y'
      }-1 hover:scale-105 ease-in-out duration-200 md:col-span-${span}`}
    >
      {children}
    </Link>
  ) : (
    <div className={`m${hasNoExtraPadding ? 't' : 'y'}-1 md:col-span-${span}`}>
      {children}
    </div>
  )

const getBackground = (background: CardElement) => {
  const {
    type = 'solid',
    color = '#FFFFFF',
    image,
    gradient: { start, end, degree } = {
      start: '#0000ff',
      end: '#ff00ff',
      degree: 90,
    },
    position = 'center',
  } = background

  return (() => {
    switch (type) {
      case 'gradient':
        return {
          background: `linear-gradient(${degree}deg, ${start}, ${end})`,
        }
      case 'image':
        if (!image) return { backgroundColor: color }

        return {
          backgroundImage: `url(${image?.url})`,
          backgroundSize: 'cover',
          backgroundPosition: position?.split('-').join(' '),
        }
      default:
        return { backgroundColor: color }
    }
  })()
}

export default Card
