import dynamic from 'next/dynamic'
import { FC } from 'react'
import {
  componentMetadata,
  SectionComponent,
} from '@BidftaStrapi/Components/types'
import Card from '@BidftaStrapi/Elements/Card'

const loadComponent = (componentType: ComponentType) => {
  const componentPath = componentMetadata[componentType]

  return dynamic<SectionComponent>(() => import(`../${componentPath}`))
}

const Section: FC<SectionComponent> = (props) => {
  const { componentType, card, span } = props
  const DynamicComponent = loadComponent(componentType)
  return (
    <Card span={span} {...card}>
      <DynamicComponent {...props} />
    </Card>
  )
}

export type ComponentType = keyof typeof componentMetadata

export default Section
